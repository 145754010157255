import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, Space, Alert } from 'antd';
import _ from 'lodash';
import './../../assets/styles.css';
import 'antd/dist/antd.css';


export const Radioes = ({ name, question, hissu_flag, options, send_type, disabled, message }) => {
    const {control, formState: {errors}} = useFormContext();
    const errname = _.get(errors, name);

    return(
        <div className="top-line">
            <label className="indent">
                <span className="question">{name}.</span>
                {question}
                {hissu_flag && send_type === 1 && (<span className="hissu">【必須】</span>)}
            </label>
            <div className="side-mage">
                <Controller
                    control={control}
                    name={name}
                    rules={{required: {value: hissu_flag, message: message}}}
                    render={({value, onChange}) => (
                        <Radio.Group defaultValue={value}
                            disabled={disabled} onChange={(e)=>onChange(e.target.value)}>
                            <Space direction="vertical">
                                {options.length > 0 && options.map((option, cnt) => (
                                    <Radio key={cnt} value={option.value}>{option.label}</Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    )}
                />
            </div>
            <div className="alert-error">
                {errname && <Alert className="alert-error"
                    message={errname.message} type="error" showIcon />}
            </div>
        </div>
    );
}