import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, Space, Alert } from 'antd';
import _ from 'lodash';
import './../../assets/styles.css';
import 'antd/dist/antd.css';


export const Checkboxes = ({ name, question, hissu_flag, options, send_type, disabled, message }) => {
    const {control, formState: {errors}, setValue, setError, clearErrors} = useFormContext();
    let errname = _.get(errors, name);
    // IE用にreact-hook-form(v6)にversionDownで対応（validationが動作しない箇所を補う）
    const handleChange = e => {
        if (e.length > 0 ) {
            setValue(name, e);
            if (hissu_flag) {
                clearErrors(name);
            }
        } else {
            setValue(name,"");
            if (hissu_flag) {
                setError(name,{ types: "required", message: message });
            }
        }
    };

    return(
        <div className="top-line">
            <label className="indent">
                <span className="question">{name}.</span>
                {question}
                {hissu_flag && send_type===1 && (<span className="hissu">【必須】</span>)}
            </label>
            <div className="side-mage">
                <Controller
                    control={control}
                    name={name}
                    rules={{required: {value: hissu_flag, message: message}}}
                    render={({value}) => (
                        <Checkbox.Group defaultValue={value} disabled={disabled} onChange={handleChange}>
                            <Space direction="vertical">
                                {options.length > 0 && options.map((option, cnt) => (
                                    <Checkbox key={cnt} value={option.value} >{option.label}</Checkbox>
                                ))}
                            </Space>
                        </Checkbox.Group>
                    )}
                />
            </div>
            <div className="alert-error">
                {errname &&
                    <Alert className="alert-error" message={errname.message} type="error" showIcon />}
            </div>
        </div>
    );
}

/*
                        <Checkbox.Group defaultValue={value} disabled={disabled} onChange={onChange}>
                            <Space direction="vertical">
                                {options.length > 0 && options.map((option, cnt) => (
                                    <Checkbox key={cnt} value={option.value}>{option.label}</Checkbox>
                                ))}
                                {value}
                            </Space>
                        </Checkbox.Group>
*/